import { useState, useEffect } from 'react';
import axios from 'axios';
import './manageGoByOrder.css';

function ManageGoByOrder({ role, username, apiRoot }) {
  const [businessDetails, setBusinessDetails] = useState(null);
  const [states, setStates] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [toast, setToast] = useState({ message: '', success: false });
  const [isLoading, setIsLoading] = useState(false);

  

  function showToast(message, success) {
    setToast({ message, success });

    // Hide the toast after 2 seconds
    setTimeout(() => {
        setToast({ message: '', success: false });
    }, 2000); // Display for 2 seconds
  }

  const fetchStates = async () => {
    try {
      const response = await axios.get(apiRoot + 'go_by_logic.php?action=get_states');
      setStates(response.data.message);
    } catch (error) {
      showToast(('Error fetching states: '+ error),false);
    }
  };

  const fetchBusinessTypes = async () => {
    try {
      const response = await axios.get(apiRoot + 'go_by_logic.php?action=get_business_types');
      setBusinessTypes(response.data.message);
    } catch (error) {
      showToast(('Error fetching business types: '+error), false);
    }
  };
  
  const fetchCompanies = async (stateId) => {
    try {
      const response = await axios.get(apiRoot + `go_by_logic.php?action=get_all_companies`);
      if (response.data.success) {
        setCompanies(response.data.message); 
      } else {
        setCompanies([]);  // limpiar si no hay compañías
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
    //console.log('Companies:', companies);
  };
  
  // Load states
  useEffect(() => {
    fetchStates();
  }, [apiRoot]);

  // Load business types
  useEffect(() => {
    fetchBusinessTypes();
  }, [apiRoot]);

  // Load companies
  useEffect(() => {
    fetchCompanies();
  }, [apiRoot]);

  
  const handleStateClick = async (stateId) => {

    setSelectedState(stateId);
    //console.log(selectedState);

    try {
      const response = await axios.get(`${apiRoot}go_by_logic.php?action=get_companies&business_type_id=${selectedBusiness}&state_id=${stateId}`);
      setBusinessDetails(response.data.message);
    } catch (error) {
      showToast(('Error fetching company details: '+ error), false);
    }
  };

  const handleBusinessClick = async (businessType) => {

    setSelectedBusiness(businessType);

    try {
      const response = await axios.get(apiRoot + `go_by_logic.php?action=get_companies&business_type_id=${businessType}&state_id=${selectedState}`);
      setBusinessDetails(response.data.message);
    } catch (error) {
      showToast(('Error fetching company details: '+ error), false);
    }
  };

  const moveCompany = async (columnId, fromIndex, toIndex) => {
    
    const column = businessDetails.find(col => col.column === columnId);
    const newCompanies = [...column.companies];

    // Mover la empresa de fromIndex a toIndex
    const [movedCompany] = newCompanies.splice(fromIndex, 1);
    newCompanies.splice(toIndex, 0, movedCompany);

    // Actualizar el estado de businessDetails
    const updatedBusinessDetails = businessDetails.map(col => {
        if (col.column === columnId) {
            return { ...col, companies: newCompanies };
        }
        return col;
    });

    setBusinessDetails(updatedBusinessDetails); // Forzar re-render

    // Preparar el objeto para el backend con nuevas posiciones
    const updatedOrder = {
        action: 'save_order',
        columnName: columnId,
        companies: newCompanies.map((company, index) => ({
            id: company.id,
            newPosition: index,
            businessTypeId: selectedBusiness, // Asegúrate de usar selectedBusiness
            stateId: selectedState // Asegúrate de usar selectedState
        }))
    };

    // Enviar el nuevo orden al backend
    try {
        const response = await axios.post(apiRoot + 'go_by_logic.php', updatedOrder);
        if (response.data.success) {
          showToast(response.data.message ,  true );
        } else {
          showToast('Error al actualizar el orden: '+ response.data.message, false );
        }
    } catch (error) {
        showToast('Error de conexión.',false );
    }
  };

  const handleAddCompany = async (companyId, columnId, selectedState, selectedBusiness) => {
    try {
      setIsLoading(true);
  
      // Encontrar la columna correspondiente en el estado `businessDetails`
      const column = businessDetails.find(col => col.column === columnId);
      const currentPosition = column.companies.length; // Contar la cantidad actual de compañías en la columna
  
      // Crear un nuevo objeto de compañía (mock)
      const newCompany = {
        id: companyId, // El ID de la nueva compañía
        name: companies.find(company => company.id == companyId)?.name || "Error",
      };      
  
      // Añadir la compañía al estado localmente
      const updatedCompanies = [...column.companies, newCompany];
      const updatedBusinessDetails = businessDetails.map(col => 
        col.column === columnId ? { ...col, companies: updatedCompanies } : col
      );
  
      setBusinessDetails(updatedBusinessDetails); // Actualizar el estado en el frontend
  
      // Preparar el objeto para enviar al backend con la nueva posición
      const add_company_to_column_data = {
        action: 'add_company_to_column',
        columnName: columnId,
        companyId: companyId,
        selectedState: selectedState,
        selectedBusiness: selectedBusiness,
        position: currentPosition // La nueva posición será la cantidad actual de elementos
      };
  
      // Realizar la solicitud para agregar la compañía en el backend
      const response = await axios.post(apiRoot + "go_by_logic.php", add_company_to_column_data);
  
      if (response.data.success) {
        showToast(response.data.message, true);
      } else {
        showToast('Error al agregar la compañía: ' + response.data.message, false);
      }
    } catch (error) {
      showToast('Error en la solicitud: ' + error, false);
    } finally {
      setIsLoading(false);
      //handleBusinessClick(selectedBusiness);
    }
  };
  
  
  const handleDeleteCompany = async (columnId, companyId) => {
    
    setIsLoading(true);
    const column = businessDetails.find(col => col.column === columnId);
    const newCompanies = [...column.companies];
 
    // Encontrar la posición de la compañía a eliminar
    const indexToDelete = newCompanies.findIndex(company => company.id === companyId);

    if (indexToDelete === -1) {
      console.error('No se encontró la compañía para eliminar');
      return;
    }

    // Mover la compañía a eliminar a la última posición
    const movedCompany = newCompanies.splice(indexToDelete, 1)[0];
    newCompanies.push(movedCompany);

    // Actualizar el estado local (opcional, pero puede ser útil para la experiencia del usuario)
    const updatedBusinessDetails = businessDetails.map(col => {
      if (col.column === columnId) {
        return { ...col, companies: newCompanies };
      }
      return col;
    });

    setBusinessDetails(updatedBusinessDetails); // Actualizar el estado local

    // Preparar el objeto para el backend con nuevas posiciones
    const updatedOrder = {
      action: 'save_order',
      columnName: columnId,
      companies: newCompanies.map((company, index) => ({
        id: company.id,
        newPosition: index,
        businessTypeId: selectedBusiness, // Asegúrate de usar selectedBusiness
        stateId: selectedState // Asegúrate de usar selectedState
      }))
    };

    // Enviar el nuevo orden al backend
    try {
      const response = await axios.post(apiRoot + 'go_by_logic.php', updatedOrder);
      if (response.data.success) {
        // Eliminar la compañía desde el frontend después de actualizar el orden
        const deleteResponse = await axios.post(apiRoot + 'go_by_logic.php', {
          action: 'delete_company_from_column',
          columnName: columnId,
          company: companyId,
          selectedState: selectedState,
          selectedBusiness: selectedBusiness,
        });

        if (deleteResponse.data.success) {
          showToast(deleteResponse.data.message, true);
          handleBusinessClick(selectedBusiness);
        } else {
          showToast('Error al eliminar la compañía: ' + deleteResponse.data.message, false);
        }
      } else {
        showToast('Error al actualizar el orden: ' + response.data.message, false);
      }
    } catch (error) {
      showToast('Error de conexión.', false);
    } finally {
      setIsLoading(false);
  }
  };

  const handleDeleteBusinessType = async (businessType) => {
    // Preguntar al usuario si desea eliminar el tipo de negocio
    const confirmDelete = window.confirm(`¿Estás seguro que deseas eliminar el tipo de negocio ?`);
    
    if (!confirmDelete) {
      // Si el usuario cancela, salir de la función
      return;
    }
  
    // Preparar el objeto para el backend con nuevas posiciones
    const delete_business_type = {
      action: 'delete_business_type',
      businessType: businessType,
    };
  
    try {
      setIsLoading(true);
      const response = await axios.post(apiRoot + "go_by_logic.php", delete_business_type);
  
      if (response.data.success) {
        // Actualizar la lista de usuarios o mostrar un mensaje
        showToast(response.data.message, true);
        setBusinessTypes(['']);  // Limpia temporalmente la lista
        fetchBusinessTypes();    // Vuelve a obtener los tipos de negocio actualizados
      } else {
        showToast('Algo salió mal: ' + response.data.message, false);
      }
    } catch (error) {
      showToast('Algo salió mal: ' + error, false);
    } finally {
      setIsLoading(false);  // Desactivar estado de carga
    }
  };  

  const handleDragStart = (e, columnId, index) => {
    e.dataTransfer.setData('text/plain', `${columnId},${index}`);
  };

  const handleDrop = (e, columnId) => {
    const [draggedColumnId, draggedIndex] = e.dataTransfer.getData('text/plain').split(',');
    const targetIndex = e.target.dataset.index;

    if (draggedColumnId === columnId && targetIndex !== undefined) {
      moveCompany(columnId, draggedIndex, targetIndex);
    }
  };

  const handleAddBusinessType = async () => {
    const newBusinessType = document.getElementById('newBusinessTypeInput').value.toUpperCase();
    let goodToGo = true
    
    // Evitar enviar un tipo de negocio vacío
    if (newBusinessType.trim() === '') {      
      return;
    }
    
    // Evitar duplicar un tipo de negocio vacío
    businessTypes.forEach(row=>{    
      if (row.name === newBusinessType){
        goodToGo = false;
        showToast(('El tipo de negocio ' + newBusinessType + ' ya existe'), false );
        return;
      }
    });

      if (goodToGo) {
        // Preparar el objeto para el backend con nuevas posiciones 
        const add_business_type = {
          action: 'add_business_type',
          new_business_type: newBusinessType,
        };   
        try {
          setIsLoading(true);
          //console.log(apiRoot + "admin_logic.php", add_business_type);
          const response = await axios.post( apiRoot + "go_by_logic.php", add_business_type);
          if (response.data.success) {     
              showToast(response.data.message, true );
              fetchBusinessTypes();
            } else {
              showToast(('algo salió mal: '+ response.data.message), false );
          }
        } catch (error) {
            showToast(('algo salió mal: '+ error), false );
        } finally {
          setIsLoading(false);  // Desactivar estado de carga
        }
      }
    }

  function BusinessTypeSelector({ businessTypes, onBusinessClick }) {
    return (
      <aside className='admin_left_aside'>
        <h2>Tipo de Negocio</h2>
        <div className='agg_business_type_div'>
          <input 
            name='new_business_type'
            type="text"
            id="newBusinessTypeInput"
            placeholder="Nuevo tipo de negocio"
            disabled={isLoading} // Deshabilitar mientras se carga
          />
          <button 
            className='agg_business_button'
            onClick={() => handleAddBusinessType()}
          >
          Add
          </button>
        </div>
        <ul>
          {businessTypes.map((businessType) => (
            <li key={businessType.id}              
                onClick={() => onBusinessClick(businessType.id)}
                className={`business-type ${businessType.id === selectedBusiness ? 'active' : ''}`}
              >
                <button 
                className='delete_company_button'
                onClick={() => handleDeleteBusinessType(businessType.id)}
                >X</button>
                  {businessType.name}              
            </li>
          ))}
        </ul>
      </aside>
    );
  }

  function StateSelector({ states }) {
    return (
      <div className="state-banner">
        {states.map((state) => (
          <button
            key={state.id}
            onClick={() => handleStateClick(state.id)}
            className={`state-buttons ${state.id === selectedState ? 'active' : ''}`}
          >
            {state.state_name}
          </button>
        ))}
      </div>
    );
  }

  function CompanyColumns() {
    if (!businessDetails || businessDetails.length === 0) {
      return <p>Seleccione un tipo de negocio y estado</p>;
    }
    

    const columnNamesMap = {
      '1': 'NEW VENTURE',
      '2': '1 YEAR',
      '3': '2 YEARS',
      '4': '3+ YEARS'
    };
    
    return (
      <div className="admin_company-columns">
        {['1', '2', '3', '4'].map((columnName) => {
          // Obtener los datos de la columna actual en businessDetails
          const columnData = businessDetails.find(col => col.column === columnName);
    
          return (
            <div key={columnName} className="admin_company-column">
              <h3>{columnNamesMap[columnName]}</h3>
    
              {/* Dropdown para agregar una compañía */}
              <select onChange={(e) => handleAddCompany(e.target.value, columnName, selectedState, selectedBusiness)}>
                <option value="">Add Company</option>
                {Array.isArray(companies) && companies.length > 0 ? (
                  companies
                    // Filtrar compañías que NO están en esta columna específica
                    .filter(company => 
                      !columnData?.companies.some(colCompany => colCompany.id === company.id)
                    )
                    .map(company => (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    ))
                ) : (
                  <option disabled>No companies available</option>
                )}
              </select>
    
              {/* Lista de compañías en la columna */}
              <ul>
                {columnData?.companies.map((company, index) => (
                  <li
                    key={company.id}
                    className="company-buttons"
                    draggable
                    onDragStart={(e) => handleDragStart(e, columnName, index)}
                    onDrop={(e) => handleDrop(e, columnName)}
                    onDragOver={(e) => e.preventDefault()} // Necesario para permitir soltar
                    data-index={index}
                  >
                    <button 
                      className='delete_company_button' 
                      onClick={() => handleDeleteCompany(columnName, company.id)}
                    >
                      X
                    </button>
                    {company.name}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <StateSelector states={states} />
      <div id="main-content">
        <BusinessTypeSelector 
          businessTypes={businessTypes} 
          onBusinessClick={handleBusinessClick} 
        />
        <div id="admin_business-info">
          {(selectedBusiness && selectedState) ? (
            <CompanyColumns />
          ) : (
            <p>Seleccione un tipo de negocio y estado</p>
          )}
        </div>  
      </div>
      {toast.message && (
            <div className={`toast ${toast.success ? 'success' : 'error'}`}>
            {toast.message}
            </div>
      )}
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner-out">          
            <div className="spinner-in"></div>
          </div>  
        </div>
      )}
    </div>
  );
}

export default ManageGoByOrder;
