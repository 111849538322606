import { useState, useEffect } from 'react';
import axios from 'axios';
import './insurers.css';


function Insurers({ role, username, apiRoot,showToast }) {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ message: '', success: false });


    function showToast(message, success) {
        setToast({ message, success });
    
        // Hide the toast after 2 seconds
        setTimeout(() => {
            setToast({ message: '', success: false });
        }, 2000); // Display for 2 seconds
      }

    useEffect(() => {
        fetchCompanies();
    }, [apiRoot]);

    // Función para obtener la lista de compañías desde el backend
    const fetchCompanies = async () => {
        try {
            const response = await axios.get(apiRoot + `go_by_logic.php?action=get_all_companies`);
            setCompanies(response.data.message);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const clearField = async (fieldID) => {
        document.getElementById(fieldID).value = "";
    }

    // Función para agregar una nueva compañía
    const handleAddCompany = async () => {
        const newCompanyInput = document.getElementById('newCompanyInput').value.toUpperCase();
        let goodToGo = true
        let cantInsurers = 1
            // Evitar enviar un tipo de negocio vacío
        if (newCompanyInput === '') {      
            return;
        }
      
        // Evitar duplicar un tipo de negocio vacío
        companies.forEach(row=>{     
            cantInsurers +=1;       
            if (row.name === newCompanyInput){
                goodToGo = false;
                showToast(('La compañia ' + newCompanyInput + ' ya existe'), false );
                return;
            }
        });

        if (goodToGo) {
                        // Preparar el objeto para el backend
            const newCompanyId = cantInsurers + 1
            
            //console.log("id="+newCompanyId);

            const newCompany = {
                action: 'add_company',
                new_Company: newCompanyInput,
                id: newCompanyId,
            }; 
            try { 
                setIsLoading(true);
                const response = await axios.post(apiRoot + 'go_by_logic.php?add_company', newCompany);
                if (response.data.success) {
                    // Actualizar la lista de compañías después de agregar
                    fetchCompanies();
                    showToast('Compañía ' + newCompanyInput +' añadida exitosamente.',true);
                    clearField('newCompanyInput');
                } else {
                    showToast('Error al añadir la compañía: '+ response.data.message, false);
                } 
            } catch (error) {
                console.error('Error adding company:', error);
                showToast(('Algo salió mal: '+ error), false)
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Función para eliminar una compañía
    const handleDeleteCompany = async (companyId) => {
        const companyName = companies.find(company => company.id === companyId).name;
        const confirmDelete = window.confirm(`¿Está seguro que deseas eliminar la aseguradora ${companyName}?`);
    
        if (!confirmDelete) {
          // Si el usuario cancela, salir de la función
          return;
        }

        const data = {
            action: 'delete_company',
            company_id: companyId,
        };
        try {
            setIsLoading(true);
            const response = await axios.post(apiRoot + 'go_by_logic.php',data)

            if (response.data.success) {
                // Actualizar la lista de compañías después de eliminar
                fetchCompanies();
                showToast('Compañía eliminada exitosamente.' , true);
            } else {
                showToast('Error al eliminar la compañía.', false);
            }
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div >
            <h2>Lista de Compañías</h2>
            <div className='div_add_insurer'>
                <input 
                    type="text"
                    id="newCompanyInput"
                    placeholder="Nueva compañía"
                />
                <button id="add_insurer_button" onClick={handleAddCompany} disabled={isLoading}>Agregar Compañía</button>
            </div>

            <ul className='insurer_list'>
                {companies.map(company => (
                    <li className="insurer_list_li" key={company.id}>
                        <button className="insurer_button"  onClick={() => handleDeleteCompany(company.id)}>X</button>
                        {company.name}                        
                    </li>
                ))}
            </ul>
            
                
            {toast.message && (
                <div className={`toast ${toast.success ? 'success' : 'error'}`}>
                {toast.message}
                </div>
          )}
        </div>
    );
}

export default Insurers;
