import { useState, useEffect } from 'react';
import axios from 'axios';
import './admin.css';
import ManageGoByOrder from './manageGoByOrder';
import Insurers from './insurers';
import States from './states';
import Procedure from '../procedures/procedures';
import StatesInfo from './stateInfo';
import BusinessInfo from './businessInfo';


function Admin({ role, username, apiRoot }) {
  const [selectedAdminSelector, setSelectedAdminSelector] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [toast, setToast] = useState({ message: '', success: false });
  const [isLoading, setIsLoading] = useState(false);


  function showToast(message, success) {
    setToast({ message, success });

    // Hide the toast after 2 seconds
    setTimeout(() => {
        setToast({ message: '', success: false });
    }, 2000); // Display for 2 seconds
  }

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(apiRoot + "login_logic.php", {
          params: { action: 'get_users' }
        });
        setUsuarios(response.data.message);
        
      } catch (error) {
        showToast("Error al cargar los usuarios.",false);
      }
    };
    fetchUsuarios();
  }, [apiRoot]);

  const handleAdminClick = (selector) => {
    setSelectedAdminSelector(selector);
  };

	const handleSave = async (usuario) => {
    const targetUsername = document.getElementById(`username-${usuario.id}`).value; 
    const email = document.getElementById(`email-${usuario.id}`).value; 
    const newRole = document.getElementById(`role-${usuario.id}`).value; 
    const active = document.getElementById(`active-${usuario.id}`).checked ? 1 : 0; // Obtener el valor del checkbox
    const password = document.getElementById(`password-${usuario.id}`).value;

    const formData = new FormData();
    formData.append('action', 'edit_user');
    formData.append('id', usuario.id);
    formData.append('targetUsername', targetUsername);
    formData.append('username', username);
    formData.append('email', email);
    formData.append('role', role);
    formData.append('newRole', newRole);
    formData.append('active', active);
    formData.append('password', password);
    
    try {
      setIsLoading(true);
        const response = await axios.post(apiRoot + "login_logic.php", formData);
        if (response.data.success) {
            // Actualizar la lista de usuarios o mostrar un mensaje
            setUsuarios(usuarios.map(u => (u.id === usuario.id ? { ...u, targetUsername, email, role, active } : u)));
            //console.log("usuarios: "+ usuarios.username)
            showToast('Usuario actualizado con éxito.', true );
            
          } else {
            showToast(('algo salió mal: '+ response.data.message), false );
        }
    } catch (error) {
        showToast(('algo salió mal: '+ error), false );
    }finally {
      setIsLoading(false); 
    }
	}	;

  const handleDelete = async (usuarioId) => {

    const confirmDelete = window.confirm(`¿Está seguro que deseas eliminar este Usuario ?`);
    
    if (!confirmDelete) {
      // Si el usuario cancela, salir de la función
      return;
    }

    const formData = new FormData();
    formData.append('action', 'delete_user');
    formData.append('id', usuarioId);
    formData.append('username', username);
    formData.append('role', role);

    try {
      setIsLoading(true);
        const response = await axios.post(apiRoot + "login_logic.php", formData);
        if (response.data.success) {
            // Eliminar el usuario de la lista
            setUsuarios(usuarios.filter(u => u.id !== usuarioId));
            showToast(("Usuario eliminado correctamente"), true);
        } else {
          showToast(response.data.message, false);
        }
    } catch (error) {
      showToast(("Error al eliminar el usuario: "+ error), false);
    }finally {
      setIsLoading(false); 
    }
  };

	function Usuarios() {
    return (
        <div className='admin-User-table'>
            <table >
                <thead>
                    <tr>
                        <th>Nombre de Usuario</th>
                        <th>Contraseña</th> 
                        <th>Correo</th>
                        <th>Rol</th>
                        <th>Activo</th>
                    </tr>
                </thead> 
                <tbody>
                    {usuarios.map(usuario => (
                        <tr key={usuario.id}>
                            <td>
                                <input
                                    type="text"
                                    defaultValue={usuario.username} // Usar defaultValue en lugar de value
                                    id={`username-${usuario.id}`} // Asignar un ID único
                                />
                            </td>
                            <td>
                                <input
                                    type="password" // Campo para la contraseña
                                    placeholder="Nueva contraseña" // Placeholder para el campo
                                    id={`password-${usuario.id}`} // Asignar un ID único
                                />
                            </td>
                            <td>
                                <input
                                    type="email"
                                    defaultValue={usuario.email} // Usar defaultValue
                                    id={`email-${usuario.id}`} // Asignar un ID único
                                />
                            </td>
                            <td>
                                <select
                                    defaultValue={usuario.role} // Usar defaultValue
                                    id={`role-${usuario.id}`} // Asignar un ID único
                                >
                                    <option value="admin">Admin</option>
                                    <option value="super_admin">Super Admin</option>
                                    <option value="user">User</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    defaultChecked={usuario.active === 1} // Usar defaultChecked
                                    id={`active-${usuario.id}`} 
                                    style={{ transform: 'scale(1.7)',accentColor:'#1121b1' }}// Asignar un ID único
                                />
                            </td>
                            <td>
                              <button onClick={() => handleSave(usuario)}>Guardar</button>
                            </td>
                            <td>
                              <button onClick={() => handleDelete(usuario.id)} style={{ marginLeft: '10px', backgroundColor: 'red' }}>
                                  Eliminar
                              </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {toast.message && (
            <div className={`toast ${toast.success ? 'success' : 'error'}`}>
            {toast.message}
            </div>
            )}
        </div>
    );
	}

  function AdminSelector() {
    return (
      <ul>
        <li
            onClick={() => handleAdminClick('Gestionar Usuarios')}
            className={`admin-left-aside ${selectedAdminSelector === 'Gestionar Usuarios' ? 'active' : ''}`}>
            Gestionar Usuarios
        </li>
        <li
            onClick={() => handleAdminClick('Gestionar orden Go_by')}
            className={`admin-left-aside ${selectedAdminSelector === 'Gestionar orden Go_by' ? 'active' : ''}`}>
            Gestionar orden Go_by
        </li>
        <li
            onClick={() => handleAdminClick('Gestionar aseguradoras')}
            className={`admin-left-aside ${selectedAdminSelector === 'Gestionar aseguradoras' ? 'active' : ''}`}>
            Gestionar Aseguradoras
        </li>
        <li
            onClick={() => handleAdminClick('Gestionar Estados')}
            className={`admin-left-aside ${selectedAdminSelector === 'Gestionar Estados' ? 'active' : ''}`}>
            Gestionar Estados
        </li>
        <li
            onClick={() => handleAdminClick('Estados Info')}
            className={`admin-left-aside ${selectedAdminSelector === 'Estados Info' ? 'active' : ''}`}>
            Estados Info
        </li>
        <li
            onClick={() => handleAdminClick('Business Info')}
            className={`admin-left-aside ${selectedAdminSelector === 'Business Info' ? 'active' : ''}`}>
            Business Info
        </li>
        <li
            //onClick={() => handleAdminClick('Gestionar Procedimientos')}
            className={`admin-left-aside ${selectedAdminSelector === 'Gestionar Procedimientos' ? 'active' : ''}`}>
            Gestionar Procedimientos
        </li>
      </ul>
    );
  }

  function AdminContent(showToast) {
    switch (selectedAdminSelector) {
      case 'Gestionar Usuarios':
        return <Usuarios  
          role={role} 
          showToast={showToast}/>;

      case 'Gestionar orden Go_by':
        return <ManageGoByOrder 
          role={role} 
          username={username} 
          apiRoot={apiRoot}         
          showToast={showToast} />;

      case 'Gestionar Estados':
        return <States 
          role={role} 
          username={username} 
          apiRoot={apiRoot}         
          showToast={showToast} />;

      case 'Gestionar aseguradoras':
        return <Insurers 
          role={role} 
          username={username} 
          apiRoot={apiRoot}         
          showToast={showToast} />;

      case 'Estados Info':
        return <StatesInfo 
          role={role} 
          username={username} 
          apiRoot={apiRoot}         
          showToast={showToast} />;    

      case 'Business Info':
        return <BusinessInfo 
          role={role} 
          username={username} 
          apiRoot={apiRoot}         
          showToast={showToast} />;        

      case 'Gestionar Procedimientos':
        return <Procedure 
          role={role} 
          username={username} 
          apiRoot={apiRoot}         
          showToast={showToast} />;
      default:
        return <h1>Seleccione una opción del menú</h1>;
    }
  }

  return (
    <div className='admin-page'>
      <div className='admin-left-aside'>
        <AdminSelector />
      </div>
      <div className="admin-main-content">
        <AdminContent 
        showToast={showToast}/>
      </div>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner-out">          
            <div className="spinner-in"></div>
          </div>  
        </div>
      )}
    </div>
  );
}

export default Admin;
